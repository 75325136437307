import React from 'react';

import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { useDataStore } from '../../store';

import logo from '../../assets/logo.svg';

import './Navbar.scss';

const Navbar = () => {
  const contactPageRender = useDataStore((state) => state.contactPageRender);
  const setShouldRender = useDataStore((state) => state.setShouldRender);
  const setContactPageRender = useDataStore((state) => state.setContactPageRender);

  const handleContactTrue = () => {
    setContactPageRender(true);
  };

  const handleContactFalse = () => {
    setContactPageRender(false);
  };

  const handleJoin = () => {
    setShouldRender(true);
  };

  const handleNav = () => {
    const mobileSideNav = document.querySelector('.mobileSideNav');
    const mobileSideNavBtn = document.querySelector('.mobileSideNavBtn');
    mobileSideNav.classList.toggle('hidden');
    mobileSideNavBtn.classList.toggle('hidden');
  };

  return (
    <div className='navbarWrapper' id='navbar'>
      <div className='navbar'>
        <NavLink className='navbarLogo' to='/' onClick={handleContactFalse}>
          <img src={logo} alt='logo' />
        </NavLink>
        <div className='navbarLinks'>
          <HashLink
            id='navLink'
            to='/#navbar'
            scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            onClick={handleContactFalse}
          >
            Home
          </HashLink>
          <HashLink
            id='navLink'
            to='/#about'
            scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
          >
            About Us
          </HashLink>
          <HashLink id='navLink' to='/#team' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
            Team
          </HashLink>
          <HashLink
            id='navLink'
            to='/#services'
            scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
          >
            Services
          </HashLink>
        </div>
        <div className='navbarCTA'>
          {contactPageRender ? null : (
            <div className='CTA' id='join' onClick={handleJoin}>
              Join Us
            </div>
          )}
          <NavLink className='CTA' id='contact' to='/Service' onClick={handleContactTrue}>
            Contact Us
          </NavLink>
          <div className='mobileSideNavBtn' onClick={handleNav}>
            <svg width='36' height='24' viewBox='0 0 36 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M2 24H34C35.1 24 36 23.1 36 22C36 20.9 35.1 20 34 20H2C0.9 20 0 20.9 0 22C0 23.1 0.9 24 2 24ZM2 14H34C35.1 14 36 13.1 36 12C36 10.9 35.1 10 34 10H2C0.9 10 0 10.9 0 12C0 13.1 0.9 14 2 14ZM0 2C0 3.1 0.9 4 2 4H34C35.1 4 36 3.1 36 2C36 0.9 35.1 0 34 0H2C0.9 0 0 0.9 0 2Z'
                fill='black'
              />
            </svg>
          </div>
        </div>

        <div className='mobileSideNav hidden'>
          <div className='mobileSideNavBtn' id='closeNav' onClick={handleNav}>
            <svg width='24' height='24' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M22.2591 17L32.44 6.81913C33.8923 5.36684 33.8923 3.01226 32.44 1.55998C30.9876 0.107693 28.6332 0.107693 27.1808 1.55998L16.9999 11.7409L6.81906 1.55998C5.36678 0.107693 3.0122 0.107693 1.55992 1.55998C0.107632 3.01226 0.107632 5.36684 1.55992 6.81913L11.7408 17L1.55992 27.1809C0.107632 28.6332 0.107632 30.9877 1.55992 32.44C2.28606 33.1661 3.23776 33.5292 4.18945 33.5292C5.14115 33.5292 6.09292 33.1662 6.81899 32.44L16.9999 22.2591L27.1808 32.44C27.907 33.1661 28.8587 33.5292 29.8104 33.5292C30.762 33.5292 31.7137 33.1662 32.4399 32.44C33.8922 30.9877 33.8922 28.6332 32.4399 27.1809L22.2591 17Z'
                fill='white'
              />
            </svg>
          </div>
          <div className='mobileSideNavLinks'>
            <HashLink
              id='navLink'
              to='/#navbar'
              scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
              onClick={() => {
                handleContactFalse();
                handleNav();
              }}
            >
              Home
            </HashLink>
            <HashLink
              id='navLink'
              to='/#about'
              scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
              onClick={handleNav}
            >
              About Us
            </HashLink>
            <HashLink
              id='navLink'
              to='/#team'
              scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
              onClick={handleNav}
            >
              Team
            </HashLink>
            <HashLink
              id='navLink'
              to='/#services'
              scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
              onClick={handleNav}
            >
              Services
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
