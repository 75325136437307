import React from 'react';

import { HashLink } from 'react-router-hash-link';

import logowhite from '../../assets/logo white.svg';

import email from '../../assets/logos/email.svg';
import phone from '../../assets/logos/phone.svg';
import location from '../../assets/logos/loc.svg';
import facebook from '../../assets/logos/facebook.svg';
import instagram from '../../assets/logos/instagram.svg';
import linkedin from '../../assets/logos/linkedin.svg';

import './Footer.scss';

const Footer = () => {
  return (
    <div className='footerWrapper'>
      <div className='footer-col1'>
        <img className='logo' src={logowhite} alt='' />
        <div className='socialLinks'>
          <a href='https://www.facebook.com/piscesprojecthelp/' target='_blank' rel='noreferrer'>
            <img src={facebook} alt='' className='social' />
          </a>
          <a href='https://www.linkedin.com/in/pisces-project-help-83b25125b/' target='_blank' rel='noreferrer'>
            <img src={linkedin} alt='' className='social' />
          </a>
          <a href='https://www.instagram.com/piscesprojecthelp/' target='_blank' rel='noreferrer'>
            <img src={instagram} alt='' className='social' />
          </a>
        </div>
      </div>
      <div className='footer-col2'>
        <div className='header'>Get In Touch</div>
        <div className='col2-elem'>
          <img className='elemLogo' src={location} alt='' />
          <div className='elemData' target='_blank' rel='noreferrer'>
            AC-119/1, Prafulla Kanaan, (East)&nbsp;Kestopur,
            <br />
            Kolkata -700101
          </div>
        </div>
        <div className='col2-elem'>
          <img className='elemLogo' src={phone} alt='' />
          <a className='elemData' href='tel:9836363404'>
            +91 9836363404
          </a>
        </div>
        <div className='col2-elem'>
          <img className='elemLogo' src={email} alt='' />
          <a className='elemData' href='mailto:contact@piscesprojecthelp.com'>
            contact@piscesprojecthelp.com
          </a>
        </div>
      </div>
      <div className='footer-col3'>
        <div className='header'>Quicklinks</div>
        <HashLink
          className='col3-elem'
          to='/#navbar'
          scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
        >
          Home
        </HashLink>
        <HashLink
          className='col3-elem'
          to='/#about'
          scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
        >
          About Us
        </HashLink>
        <HashLink
          className='col3-elem'
          to='/#services'
          scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
        >
          Services
        </HashLink>
        <HashLink
          className='col3-elem'
          to='/#team'
          scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
        >
          Team
        </HashLink>
      </div>
    </div>
  );
};

export default Footer;
