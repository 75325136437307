import React from 'react';

import article from '../../assets/services/article.svg';
import blog from '../../assets/services/blog.svg';
import course from '../../assets/services/course.svg';
import literature from '../../assets/services/literature.svg';
import report from '../../assets/services/report.svg';
import research from '../../assets/services/research.svg';
import tech from '../../assets/services/tech.svg';
import thesis from '../../assets/services/thesis.svg';

import serviceData from '../../serviceData';

import './Services.scss';

const Services = () => {
  return (
    <div className='servicesWrapper' id='services'>
      <div className='servicesTitle'>OUR SERVICES</div>
      <div className='servicesHeader'>Our Content Writing Services</div>
      <div className='servicesDesc'>
        Our academic content writing company offers a wide range of services to meet your academic writing needs.
        <br />
        We specialize in providing customized and high-quality writing services for students, researchers, and scholars.
      </div>
      <div className='servicesSectionGrid'>
        <div className='serviceElement'>
          <div className='elementTop'>
            <div className='elementLogo'>
              <img src={article} alt='' />
            </div>
            <div className='elementTitle'>{serviceData.serviceName[0]}</div>
          </div>
          <div className='elementDesc'>{serviceData.serviceDesc[0]}</div>
        </div>

        <div className='serviceElement'>
          <div className='elementTop'>
            <div className='elementLogo'>
              <img src={blog} alt='' />
            </div>
            <div className='elementTitle'>{serviceData.serviceName[1]}</div>
          </div>
          <div className='elementDesc'>{serviceData.serviceDesc[1]}</div>
        </div>

        <div className='serviceElement'>
          <div className='elementTop'>
            <div className='elementLogo'>
              <img src={thesis} alt='' />
            </div>
            <div className='elementTitle'>{serviceData.serviceName[2]}</div>
          </div>
          <div className='elementDesc'>{serviceData.serviceDesc[2]}</div>
        </div>

        <div className='serviceElement'>
          <div className='elementTop'>
            <div className='elementLogo'>
              <img src={report} alt='' />
            </div>
            <div className='elementTitle'>{serviceData.serviceName[3]}</div>
          </div>
          <div className='elementDesc'>{serviceData.serviceDesc[3]}</div>
        </div>

        <div className='serviceElement'>
          <div className='elementTop'>
            <div className='elementLogo'>
              <img src={literature} alt='' />
            </div>
            <div className='elementTitle'>{serviceData.serviceName[4]}</div>
          </div>
          <div className='elementDesc'>{serviceData.serviceDesc[4]}</div>
        </div>

        <div className='serviceElement'>
          <div className='elementTop'>
            <div className='elementLogo'>
              <img src={research} alt='' />
            </div>
            <div className='elementTitle'>{serviceData.serviceName[5]}</div>
          </div>
          <div className='elementDesc'>{serviceData.serviceDesc[5]}</div>
        </div>

        <div className='serviceElement'>
          <div className='elementTop'>
            <div className='elementLogo'>
              <img src={article} alt='' />
            </div>
            <div className='elementTitle'>{serviceData.serviceName[6]}</div>
          </div>
          <div className='elementDesc'>{serviceData.serviceDesc[6]}</div>
        </div>

        <div className='serviceElement'>
          <div className='elementTop'>
            <div className='elementLogo'>
              <img src={course} alt='' />
            </div>
            <div className='elementTitle'>{serviceData.serviceName[7]}</div>
          </div>
          <div className='elementDesc'>{serviceData.serviceDesc[7]}</div>
        </div>

        <div className='serviceElement'>
          <div className='elementTop'>
            <div className='elementLogo'>
              <img src={tech} alt='' />
            </div>
            <div className='elementTitle'>{serviceData.serviceName[8]}</div>
          </div>
          <div className='elementDesc'>{serviceData.serviceDesc[8]}</div>
        </div>
      </div>
      {/* <div className='servicesCTA'>More Services</div> */}
    </div>
  );
};

export default Services;
