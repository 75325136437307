import React from 'react';

import profile from '../../assets/profiles/Profile (1).jpg';
import profile2 from '../../assets/profiles/Profile (2).jpg';
import profile3 from '../../assets/profiles/Profile (3).jpg';
import profile4 from '../../assets/profiles/Profile (4).jpg';

import './Team.scss';

const Team = () => {
  return (
    <div className='teamWrapper' id='team'>
      <div className='teamTitle'>OUR TEAM</div>
      <div className='teamHeader'>Our Founding Partners</div>
      <div className='teamCards'>
        <div className='teamCard'>
          <div className='imageContainer'>
            <div className='teamCardImage'>
              <img src={profile3} alt='' />
            </div>
          </div>
          <div className='detailsContainer'>
            <div className='teamCardName'>Ishanava Bhadury</div>
            <div className='teamCardTitle'>Chief Executive Officer</div>
          </div>
        </div>

        <div className='teamCard'>
          <div className='imageContainer'>
            <div className='teamCardImage'>
              <img src={profile} alt='' />
            </div>
          </div>
          <div className='detailsContainer'>
            <div className='teamCardName'>Sandipan Ghosh</div>
            <div className='teamCardTitle'>Co-Founder</div>
          </div>
        </div>

        <div className='teamCard'>
          <div className='imageContainer'>
            <div className='teamCardImage'>
              <img src={profile2} alt='' />
            </div>
          </div>
          <div className='detailsContainer'>
            <div className='teamCardName'>Pritam Saha</div>
            <div className='teamCardTitle'>Co-Founder</div>
          </div>
        </div>

        <div className='teamCard'>
          <div className='imageContainer'>
            <div className='teamCardImage'>
              <img src={profile4} alt='' />
            </div>
          </div>
          <div className='detailsContainer'>
            <div className='teamCardName'>Anindita Mitra</div>
            <div className='teamCardTitle'>Managing Director</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
