import React from 'react';
import { useState } from 'react';

import { NavLink } from 'react-router-dom';

import logo from '../../assets/logo.svg';
import landingasset from '../../assets/landingasset.svg';

import { useDataStore } from '../../store';

import './Landing.scss';

const Landing = () => {
  // const contactPageRender = useDataStore((state) => state.contactPageRender);
  const setContactPageRender = useDataStore((state) => state.setContactPageRender);

  const handleContactTrue = () => {
    setContactPageRender(true);
  };

  const [isHovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <div className='landingWrapper' id='landing'>
      <div className='landing-col1'>
        <div className='landingLogo'>
          <img src={logo} alt='logo' />
        </div>
        <div className='landingTitle'>Your&nbsp;Team Of&nbsp;Veteran Content&nbsp;Writers</div>
        <div className='landingDesc'>
          Welcome to <span className='highlighted'>Pisces Project Help!</span> We are a team of experienced writers
          dedicated to providing high-quality academic writing services to students, researchers, and scholars.
        </div>
        <NavLink
          className='landingCTA'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          to='/service'
          onClick={handleContactTrue}
        >
          Get Quote
          <svg width='50' height='17' viewBox='0 0 50 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M50 8.5L36 0.417096V16.5829L50 8.5ZM0.747406 9.9H37.4V7.1H0.747406V9.9Z'
              fill={isHovered ? '#2d2459' : '#fff'}
            />
          </svg>
        </NavLink>
      </div>
      <div className='landing-col2'>
        <div className='landingImage'>
          <img src={landingasset} alt='' />
        </div>
      </div>
    </div>
  );
};

export default Landing;
