import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';

import Home from './pages/Home';
import Service from './pages/Service';

import './App.scss';

const App = () => {
  return (
    <div className='App'>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Service' element={<Service />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
