import { create } from 'zustand';

// import { devtools } from 'zustand/middleware';

let dataStore = (set) => ({
  contactPageRender: false,
  shouldRender: false,
  otherRender: false,
  setOtherRender: (otherRender) => set((state) => ({ otherRender: otherRender })),
  setContactPageRender: (contactPageRender) => set((state) => ({ contactPageRender: contactPageRender })),
  setShouldRender: (shouldRender) => set((state) => ({ shouldRender: shouldRender })),
});

// @ts-ignore
// dataStore = devtools(dataStore);

export const useDataStore = create(dataStore);
