var reviewData = [
  {
    id: 1,
    stars: 5,
    name: 'Sarah B.',
    text: 'I was struggling to complete my thesis on time, but the writers at this company saved the day! They provided excellent research and writing services, helping me to produce a high-quality thesis that met all academic requirements.',
  },
  {
    id: 2,
    stars: 5,
    name: 'John M.',
    text: 'I was skeptical about using an academic content writing company, but this company exceeded my expectations. They provided excellent writing services for my coursework, ensuring that my content was accurate, well-researched, and original.',
  },
  {
    id: 3,
    stars: 4,
    name: 'Samantha C.',
    text: 'The technical writing services provided by this company were exceptional. They helped me to produce clear and concise technical documents that were critical to the success of my project.',
  },
  {
    id: 4,
    stars: 4.5,
    name: 'Emily R.',
    text: 'I have used this company for all my academic writing needs and they have always delivered top-notch content. Their writers are knowledgeable, professional, and always meet my deadlines. Highly recommended!',
  },
];

export default reviewData;
