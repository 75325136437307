import React from 'react';
import { useState } from 'react';

import { NavLink } from 'react-router-dom';

import { useDataStore } from '../../store';

import aboutasset from '../../assets/aboutasset.jpg';

import './About.scss';

const About = () => {
  // const contactPageRender = useDataStore((state) => state.contactPageRender);
  const setContactPageRender = useDataStore((state) => state.setContactPageRender);

  const handleContactTrue = () => {
    setContactPageRender(true);
  };

  const [isHovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <div className='aboutWrapper' id='about'>
      <div className='about'>
        <div className='aboutImage'>
          <img src={aboutasset} alt='' />
        </div>
        <div className='about-col2'>
          <div className='aboutTitle'>About Us</div>
          <div className='aboutHeader'>
            We Provide The{' '}
            <span className='highlightText'>
              Best
              <br id='breaker' /> Writing
            </span>{' '}
            Service
          </div>
          <div className='aboutDesc'>
            We take pride in our commitment to excellence and quality. Every piece of content we produce is thoroughly
            researched, meticulously crafted, and carefully edited to ensure accuracy, clarity, and originality. We also
            understand the importance of timely delivery and make every effort to meet your deadlines.
          </div>
          <NavLink
            to='/service'
            className='aboutCTA'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleContactTrue}
          >
            Get Quote
            <svg width='50' height='17' viewBox='0 0 50 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M50 8.5L36 0.417096V16.5829L50 8.5ZM0.747406 9.9H37.4V7.1H0.747406V9.9Z'
                fill={isHovered ? '#2d2459' : '#fff'}
              />
            </svg>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default About;
