import React from 'react';

import ReactStars from 'react-stars';

import reviewData from 'reviewData';

import './Review.scss';

const Review = () => {
  return (
    <div className='reviewWrapper' id='about'>
      <div className='review'>
        <div className='reviewTitle'>Testimonials</div>
        <div className='reviewHeader'>
          What
          <span id='highlight'> Our Clients </span>
          Say
        </div>
        <div className='reviewSubtext'>
          Read our user testimonials and see how we have helped clients achieve academic success with our writing
          services!
        </div>
        <div className='reviewSection'>
          {reviewData.map((review) => {
            return (
              <div className='reviewCard' key={review.id}>
                <div className='reviewCardStars'>
                  <ReactStars count={5} edit={false} value={review.stars} size={36} />
                </div>
                <div className='reviewCardContent'>"{review.text}"</div>
                <div className='reviewCardName'>
                  <div className='reviewCardName'>- {review.name}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Review;
