import React from 'react';

import { useDataStore } from '../store';

import Landing from '../components/Landing/Landing';
import LandingMobile from '../components/Landing/LandingMobile';
import LiveCounter from '../components/LiveCounter/LiveCounter';
import About from '../components/About/About';
import Services from '../components/Services/Services';
import Team from '../components/Team/Team';
import Review from '../components/Review/Review';
import Footer from '../components/Footer/Footer';
import JoinModal from 'components/JoinModal/JoinModal';

const Home = () => {
  const shouldRender = useDataStore((state) => state.shouldRender);
  return (
    <div>
      {shouldRender ? <JoinModal /> : null}
      <Landing />
      <LandingMobile />
      <LiveCounter />
      <Services />
      <About />
      <Team />
      <Review />
      <Footer />
    </div>
  );
};

export default Home;
