// @ts-nocheck
import React from 'react';
import { useState } from 'react';

import { TailSpin } from 'react-loading-icons';

import email from '../../assets/logos/email.svg';
import phone from '../../assets/logos/phone.svg';
import location from '../../assets/logos/loc.svg';

import serviceData from '../../serviceData';

import { useDataStore } from '../../store';

import './Contact.scss';

const Contact = () => {
  const [loadState, setLoadState] = useState(false);
  const [completeState, setCompleteState] = useState(false);

  const otherRender = useDataStore((state) => state.otherRender);
  const setOtherRender = useDataStore((state) => state.setOtherRender);

  const handleOther = () => {
    setOtherRender(true);
  };
  const handleOtherFalse = () => {
    setOtherRender(false);
  };

  function handleSubmit(e) {
    setCompleteState(false);

    const form = document.getElementById('quoteForm');

    e.preventDefault();

    const data = new FormData(form);
    const action = process.env.REACT_APP_ORDER_URL;

    setLoadState(true);

    fetch(action, {
      method: 'POST',
      body: data,
    })
      .then(() => {
        console.log('Success!');
        form.reset();
        setLoadState(false);
        setCompleteState(true);
        setTimeout(() => {
          setCompleteState(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        form.reset();
        setLoadState(false);
      });
  }

  return (
    <div className='contactWrapper'>
      <div className='contactTitle'>Contact Us</div>
      <div className='contact'>
        <div className='contact-col1'>
          <div className='col1Title'>Queries?</div>
          <div className='col1Desc'>Reach Out to Us</div>
          <div className='contactGroups'>
            <div className='contactGroup'>
              <div className='contactGroupTitle'>Call Us At</div>
              <div className='contactGroupItem'>
                <img className='elemLogo' src={phone} alt='' />
                <a className='elemData' href='tel:9836363404'>
                  +91 9836363404
                </a>
              </div>
              <div className='contactGroupItem'>
                <img className='elemLogo' src={phone} alt='' />
                <a className='elemData' href='tel:8582891824'>
                  +91 8582891824
                </a>
              </div>
            </div>

            <div className='contactGroup'>
              <div className='contactGroupTitle'>Email Us At</div>
              <div className='contactGroupItem'>
                <img className='elemLogo' src={email} alt='' />
                <a className='elemData' href='mailto:contact@piscesprojecthelp.com' target='_blank' rel='noreferrer'>
                  contact@piscesprojecthelp.com
                </a>
              </div>
            </div>

            <div className='contactGroup'>
              <div className='contactGroupTitle'>Reach Us At</div>
              <div className='contactGroupItem'>
                <img className='elemLogo' src={location} alt='' />
                <div className='elemData' target='_blank' rel='noreferrer'>
                  AC-119/1, Prafulla Kanaan, (East)&nbsp;Kestopur,
                  <br />
                  Kolkata -700101
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className='separator' />
        <div className='contact-col2'>
          <div className='col2Title'>Need A Quote?</div>
          {loadState ? (
            <div className='loaderWrapper'>
              <TailSpin />
            </div>
          ) : completeState ? (
            <div className='completeText'>We'll Get Back to You Soon!</div>
          ) : (
            <div className='contactForm'>
              <form onSubmit={handleSubmit} id='quoteForm' encType='multipart/form-data'>
                <div className='formGroup'>
                  <div className='formSubGroup'>
                    <label htmlFor='name'>Full&nbsp;Name</label>
                    <input
                      type='text'
                      name='name'
                      id='name'
                      className='formInput'
                      placeholder='Enter your Full Name'
                      required
                    />
                  </div>
                  <div className='formSubGroup'>
                    <label htmlFor='email'>Email</label>
                    <input
                      type='email'
                      name='email'
                      id='email'
                      className='formInput'
                      placeholder='Enter your Email'
                      required
                    />
                  </div>
                </div>
                <div className='formGroup'>
                  <label htmlFor='service'>Type&nbsp;of&nbsp;Service</label>
                  <select name='service' id='service' className='formSelect' required>
                    <option value='' disabled selected>
                      Select your option
                    </option>
                    {serviceData.serviceName.map((service, index) => (
                      <option key={index} value={service} onClick={handleOtherFalse}>
                        {service}
                      </option>
                    ))}
                    <option value='other' onClick={handleOther}>
                      Other
                    </option>
                  </select>
                </div>
                {otherRender ? (
                  <div className='formGroup' id='optional'>
                    <label htmlFor='other'>(If&nbsp;Other,&nbsp;Specify)</label>
                    <input name='other' id='other' className='formInput' />
                  </div>
                ) : null}
                <div className='formGroup' id='documents'>
                  <label htmlFor='document'>Supporting&nbsp;Doucuments</label>
                  <input
                    name='document'
                    id='document'
                    type='text'
                    className='formInput'
                    required
                    placeholder='Share link to your documents (Google Drive/Dropbox)'
                  />
                </div>
                <div className='suggestion'>
                  To avoid problems, please share the link as <b>"Anyone with link"</b>.
                </div>
                <div className='formGroupDesc'>
                  <label htmlFor='description'>Description</label>
                  <textarea
                    name='description'
                    id='description'
                    rows={4}
                    style={{ resize: 'none' }}
                    placeholder='Describe your requirement'
                  />
                </div>
                <div className='formGroupButton'>
                  <button type='submit'>Submit</button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
