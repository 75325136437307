import React from 'react';

import Contact from '../components/Contact/Contact';

const Service = () => {
  return (
    <div>
      <Contact />
    </div>
  );
};

export default Service;
