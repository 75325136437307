import React from 'react';

import './LiveCounter.scss';

const LiveCounter = () => {
  return (
    <div className='counterWrapper'>
      <div className='counterTitle'>
        We Aid Academics
        <br />
        With Their Assignments
      </div>
      <div className='counter'>
        <div className='counterItem'>
          <div className='counterContents'>
            <div className='counterItemLogo'>
              <svg width='190' height='190' viewBox='0 0 190 190' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='94.95' cy='94.95' r='94.95' fill='#2E2559' />
                <path
                  d='M140 54.5C134.45 52.75 128.35 52 122.5 52C112.75 52 102.25 54 95 59.5C87.75 54 77.25 52 67.5 52C57.75 52 47.25 54 40 59.5V132.75C40 134 41.25 135.25 42.5 135.25C43 135.25 43.25 135 43.75 135C50.5 131.75 60.25 129.5 67.5 129.5C77.25 129.5 87.75 131.5 95 137C101.75 132.75 114 129.5 122.5 129.5C130.75 129.5 139.25 131 146.25 134.75C146.75 135 147 135 147.5 135C148.75 135 150 133.75 150 132.5V59.5C147 57.25 143.75 55.75 140 54.5ZM140 122C134.5 120.25 128.5 119.5 122.5 119.5C114 119.5 101.75 122.75 95 127V69.5C101.75 65.25 114 62 122.5 62C128.5 62 134.5 62.75 140 64.5V122Z'
                  fill='white'
                />
              </svg>
            </div>
            <div className='counterDetail'>
              <div className='counterItemNumber'>
                100<sup>+</sup>
              </div>
              <div className='counterItemText'>
                Assignments
                <br />
                Completed
              </div>
            </div>
          </div>
        </div>
        <div className='counterItem'>
          <div className='counterContents'>
            <div className='counterItemLogo'>
              <svg width='191' height='190' viewBox='0 0 191 190' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='95.2' cy='94.95' r='94.95' fill='#2E2559' />
                <path
                  d='M71.1667 95.3334C80.2794 95.3334 87.6667 87.9461 87.6667 78.8334C87.6667 69.7207 80.2794 62.3334 71.1667 62.3334C62.054 62.3334 54.6667 69.7207 54.6667 78.8334C54.6667 87.9461 62.054 95.3334 71.1667 95.3334Z'
                  fill='white'
                />
                <path
                  d='M89.9583 104.5C83.505 101.223 76.3825 99.9166 71.1667 99.9166C60.9504 99.9166 40 106.182 40 118.708V128.333H74.375V124.651C74.375 120.296 76.2083 115.931 79.4167 112.292C81.9765 109.386 85.5606 106.689 89.9583 104.5Z'
                  fill='white'
                />
                <path
                  d='M114.25 102.667C102.317 102.667 78.5 110.037 78.5 124.667V135.667H150V124.667C150 110.037 126.183 102.667 114.25 102.667Z'
                  fill='white'
                />
                <path
                  d='M114.25 95.3333C125.388 95.3333 134.417 86.3044 134.417 75.1667C134.417 64.0289 125.388 55 114.25 55C103.112 55 94.0833 64.0289 94.0833 75.1667C94.0833 86.3044 103.112 95.3333 114.25 95.3333Z'
                  fill='white'
                />
              </svg>
            </div>
            <div className='counterDetail'>
              <div className='counterItemNumber'>
                50<sup>+</sup>
              </div>
              <div className='counterItemText'>
                Satisfied
                <br />
                Clients
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveCounter;
