// @ts-nocheck

import React from 'react';
import { useState } from 'react';
import { TailSpin } from 'react-loading-icons';

import { useDataStore } from '../../store';

import './JoinModal.scss';

const JoinModal = () => {
  const setShouldRender = useDataStore((state) => state.setShouldRender);

  const [loadState, setLoadState] = useState(false);
  const [completeState, setCompleteState] = useState(false);

  function handleSubmit(e) {
    setCompleteState(false);

    const form = document.getElementById('joinForm');

    e.preventDefault();

    const data = new FormData(form);
    const action = process.env.REACT_APP_CV_URL;

    setLoadState(true);

    fetch(action, {
      method: 'POST',
      body: data,
    })
      .then(() => {
        console.log('Success!');

        setLoadState(false);
        setCompleteState(true);
        setTimeout(() => {
          setShouldRender(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);

        setShouldRender(false);
      });
  }

  return (
    <div className='joinModalWrapper'>
      {loadState ? (
        <TailSpin />
      ) : completeState ? (
        <div className='joinModal'>
          <div className='completeSubmit'>Thank You For Applying!</div>
        </div>
      ) : (
        <div className='joinModal'>
          <div className='joinModalHeader'>
            <h1>Join Us</h1>
            <div className='joinModalClose' onClick={() => setShouldRender(false)}>
              <svg width='30' height='30' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M39.2857 50C37.8776 50.0041 36.4827 49.7287 35.1818 49.1898C33.8809 48.651 32.6999 47.8593 31.7071 46.8607L25 40.15L18.2893 46.8607C14.2429 50.9071 7.18571 50.9071 3.13571 46.8607C1.11428 44.8393 0 42.1464 0 39.2857C0 36.425 1.11429 33.7321 3.13929 31.7071L9.84643 25L3.13929 18.2893C1.11429 16.2679 0 13.575 0 10.7143C0 7.85357 1.11429 5.16071 3.13929 3.13571C7.18571 -0.907146 14.2429 -0.910716 18.2929 3.13928L25 9.85L31.7107 3.13928C35.7571 -0.907144 42.8143 -0.907144 46.8643 3.13928C48.8857 5.16071 50 7.85357 50 10.7143C50 13.575 48.8857 16.2679 46.8607 18.2929L40.1536 25L46.8607 31.7107C48.8857 33.7321 50 36.425 50 39.2857C50 42.1464 48.8857 44.8393 46.8607 46.8643C45.8681 47.8618 44.6875 48.6526 43.3873 49.1908C42.087 49.729 40.693 50.0041 39.2857 50ZM25 30.05L36.7607 41.8107C37.441 42.46 38.3453 42.8223 39.2857 42.8223C40.2261 42.8223 41.1304 42.46 41.8107 41.8107C42.4857 41.1357 42.8571 40.2429 42.8571 39.2857C42.8571 38.3286 42.4857 37.4357 41.8107 36.7607L30.0536 25L41.8107 13.2393C42.4857 12.5643 42.8571 11.6679 42.8571 10.7143C42.8571 9.76071 42.4857 8.86428 41.8107 8.18928C41.1313 7.53869 40.2271 7.17523 39.2864 7.17456C38.3457 7.1739 37.4411 7.53608 36.7607 8.18571L25 19.95L13.2393 8.18928C12.559 7.53999 11.6547 7.17772 10.7143 7.17772C9.77386 7.17772 8.86959 7.53999 8.18929 8.18928C7.51429 8.86428 7.14286 9.76071 7.14286 10.7143C7.14286 11.6679 7.51429 12.5643 8.18929 13.2393L19.9464 25L8.18929 36.7607C7.51429 37.4357 7.14286 38.3286 7.14286 39.2857C7.14286 40.2429 7.51429 41.1357 8.18929 41.8107C8.86871 42.4613 9.77289 42.8248 10.7136 42.8254C11.6543 42.8261 12.5589 42.4639 13.2393 41.8143L25 30.05Z'
                  fill='black'
                />
              </svg>
            </div>
          </div>
          <div className='joinModalForm'>
            <form id='joinForm' onSubmit={handleSubmit} encType='multipart/form-data'>
              <div className='joinModalFormInput'>
                <label htmlFor='name'>Full&nbsp;Name</label>
                <input type='text' name='Name' id='Name' required placeholder='Enter your full name' />
              </div>

              <div className='joinModalFormInputGroup'>
                <fieldset className='contactInfoFieldSet'>
                  <legend className='contactInfoLabel'>Contact Info</legend>
                  <div className='joinModalFormInput'>
                    <label htmlFor='phone'>Phone</label>
                    <input type='phone' name='Phone' id='Phone' required placeholder='Enter your Phone Number' />
                  </div>
                  <div className='joinModalFormInput'>
                    <label htmlFor='email'>Email</label>
                    <input type='email' name='Email' id='Email' required placeholder='Enter your Email' />
                  </div>
                </fieldset>
              </div>
              <div className='joinModalFormInput' id='CVField'>
                <label htmlFor='CV'>CV</label>
                <input
                  type='text'
                  name='CV'
                  id='CV'
                  required
                  placeholder='Share Link to your CV (Google Drive/DropBox)'
                />
              </div>
              <div className='suggestion'>
                To avoid problems, please share the link as <b>"Anyone with link"</b>.
              </div>
              <div className='joinModalFormSubmit'>
                <button type='submit'>Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default JoinModal;
