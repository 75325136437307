var serviceData = {
  serviceName: [
    'Article Writing',
    'Blog Writing',
    'Thesis Writing',
    'Report Writing',
    'Literature Review',
    'Research Proposal',
    'Dissertation',
    'Coursework',
    'Technical Writing',
  ],
  serviceDesc: [
    'Our writers can craft high-quality articles on any topic or subject, ensuring that your content is accurate, well-researched, and engaging.',
    'Our expert writers can help you create engaging and informative blog posts that will attract readers and increase your online presence.',
    'We offer comprehensive thesis writing services, including research, analysis, and writing, to help you create a compelling and well-argued thesis.',
    'Our writers can assist you in creating professional and informative reports, covering a wide range of topics and subjects.',
    'We can help you conduct a thorough and comprehensive literature review, providing critical analysis and insights on your chosen topic.',
    'Our expert writers can assist you in creating a well-written and convincing research proposal, ensuring that your proposal meets the highest academic standards.',
    'We offer comprehensive dissertation writing services, including research, analysis, and writing, to help you produce a high-quality dissertation that meets all academic requirements.',
    'Our writers can assist you in creating customized coursework assignments, ensuring that your content is well-researched, accurate, and original.',
    'We also offer technical writing services, covering a wide range of subjects and topics, ensuring that your technical documents are clear, concise, and accurate.',
  ],
};

export default serviceData;
